import isbot from 'isbot';
import { loadCMP, parseQuery, inIFrame, rgpdActive, uspActive, loadId5 } from './tools';
import { corner_instream_left } from './cornerInstreamLeft.js';
import { launchFormat,addAdUnits,getParamsToLaunchAndLoadDirectTags } from './tagTool.js';

(function (window) {
    var tagParams = [];

    //Define window variable level (if we are in iframe)
    var windowTop;
    var documentTop;

    if (inIFrame()) {
        windowTop = parent.window;
        documentTop = parent.document;
    }
    else {
        windowTop = window;
        documentTop = document;
    }

    //Variable to know if we are in refresh mode or not
    var isRefresh = false;

    loadId5(documentTop);

    //Div animations must be call one time, this variable check if a div animation is load at the page level
    windowTop.animationLoad = [];

    if (!window.d2pLoaded) { //Check if we are load in the local level (page or iframe)
        window.d2pLoaded = true;
        let readyStateOk = setInterval(function () {
            if (document.readyState === 'ready' || document.readyState === 'complete') {
                let prebidScript = loadPrebid(); //Load Prebid

                prebidScript.addEventListener("load", function () {
                    loadTags(); //Find tags in the pages et get her params
                });

                clearInterval(readyStateOk);
            }
        }, 100);
    }

    function loadTags() {
        let tags = getTags();
        getNextParam(tags);
    }

    function getNextParam(tags) {
        if (tags && tags.length > 0) {
            let tag = tags.pop();

            let tagId = getTagId(tag); //Id du tag qui nous permettra de récupérer les paramètres du tag pour l'enchère Prebid

            windowTop.animationLoad[tagId] = false;

            let runTagRequest = new XMLHttpRequest(); //Requête de récupération des paramètres sur notre API

            runTagRequest.onreadystatechange = function () {
                if (runTagRequest.readyState == XMLHttpRequest.DONE) {
                    if (runTagRequest.status == 200 || runTagRequest.status == 201) {
                        if (runTagRequest.responseText != "") {
                            tagParams.push(JSON.parse(runTagRequest.responseText));
                            getNextParam(tags);
                        }
                    }
                    else if (runTagRequest.status == 400) {
                        console.log('There was an error 400');
                        getNextParam(tags);
                    }
                    else {
                        if (runTagRequest.status != 200 && runTagRequest.status != 201 && runTagRequest.status != 0) {
                            console.log('something else other than 200 was returned: ' + runTagRequest.status + " - " + runTagRequest.responseText);
                            getNextParam(tags);
                        }
                    }
                }
            }

            runTagRequest.open("GET", DEV2PUB_API_URL + "/api/public/Dev2Pub/" + tagId, true);
            runTagRequest.send();
        }
        else {
            if (isbot(navigator.userAgent) == false) {
                launchNextTags();
            }
        }
    }

    function launchNextTags() {
        let paramsToLaunch = getParamsToLaunchAndLoadDirectTags(tagParams);

        if (paramsToLaunch) {
            let adUnits = [];
            let paramsLaunched = [];
            for (let param of paramsToLaunch) {
                //CMP integration if not exist
                if (param && param.cmp) {
                    if (!windowTop.d2pCmpInit) {
                        windowTop.d2pCmpInit = true;
                        loadCMP();
                    }
                }

                if (param && param.idTheMoneytizerFormatName) {
                    loadTheMoneytizerTag(param);
                }
                else {
                    if (param && (param.theMoneytizerAdUnit || param.adUnit)) {
                        if (param && param.theMoneytizerAdUnit) {
                            let divId = param.id;

                            if (inIFrame()) {
                                if (!window.frameElement.id) {
                                    window.frameElement.id = param.id;
                                }
                                divId = window.frameElement.id;
                            }

                            let tag = document.getElementById(param.id + "script");

                            if (param.demoMode) {
                                loadDemoTags(param,tag);
                                launchNextTags();
                            }
                            else {
                                let adDiv = document.createElement("div");
                                adDiv.id = divId;
                                tag.parentNode.insertBefore(adDiv, tag.nextSibling);
                                adUnits = addAdUnits(param, divId, adUnits);
                                param.divId = divId;
                                paramsLaunched.push(param);
                            }
                        }
                    }
                }
            }

            if (adUnits.length > 0) {
                d2ppbjs.addAdUnits(adUnits);

                //Run auction
                requestBids(paramsLaunched, adUnits, true);
                if (paramsToLaunch[0].tagRefreshDelaySeconds && paramsToLaunch[0].tagRefreshDelaySeconds > 0 /*&& paramsToLaunch[0].formatType != "VIDEO"*/) {
                    var dev2pubRefreshInterval = setInterval(function () { requestBids(paramsLaunched, adUnits, false, dev2pubRefreshInterval); }, paramsToLaunch[0].tagRefreshDelaySeconds * 1000);
                }
                else {
                    if (paramsToLaunch[0].formatType == "VIDEO") {
                        if (paramsToLaunch[0].videoContext == "OUTSTREAM") {
                            var dev2pubRefreshInterval = setInterval(function () { requestBids(paramsLaunched, adUnits, false, dev2pubRefreshInterval); }, 31000);
                        }
                    }
                }
            }
        }
    }

    

    function setConfig(params) {
        d2ppbjs.aliasBidder('appnexus', 'goodad');

        d2ppbjs.bidderSettings = {
            onetag: {
                bidCpmAdjustment: function (bidCpm) {
                    bidCpm = bidCpm * 0.9;

                    return bidCpm;
                },
                storageAllowed: true
            },
            oftmedia: {
                bidCpmAdjustment: function (bidCpm) {
                    //Adjustment
                    return bidCpm * .8;
                },
            },
            blueroostermedia: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * .7;
                },
            },
            rubicon: {
                bidCpmAdjustment: function (bidCpm) {
                    //Adjustment
                    return bidCpm * .9;
                },
            },
            adform: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * .73;
                },
            },
            criteo: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * 1.13;
                },
                storageAllowed: true
            },
            improvedigital: {
                bidCpmAdjustment: function (bidCpm) {
                    //Adjustment
                    return bidCpm * .8;
                },
            },
            smartadserver: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * .6;
                },
            },
            outbrain: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * 0.8;
                },
                storageAllowed: true
            },
            missena: {
                bidCpmAdjustment: function (bidCpm, bid) {
                    return bidCpm * 0.7;
                },
                storageAllowed: true
            },
            adagio: {
                storageAllowed: true
            },
            gumgum: {
                storageAllowed: true
            },
            ix: {
                storageAllowed: true
            },
            eplanning: {
                storageAllowed: true
            },
            appnexus: {
                bidCpmAdjustment: function (bidCpm, bid) {
                    return bidCpm * 0.75;
                },
                storageAllowed: true
            },
            openx: {
                bidCpmAdjustment: function (bidCpm, bid) {
                    return bidCpm * 0.75;
                },
                storageAllowed: true
            },
            amx: {
                storageAllowed: true
            }
        };

        let config = {
            "schain": {
                "validation": "relaxed",
                "config": {
                    "ver": "1.0",
                    "complete": 1,
                    "nodes":
                        [
                            {
                                "asi": "themoneytizer.com",
                                "sid": "74458",
                                "hp": 1
                            },
                            {
                                "asi": "152media.info",
                                "sid": "152M960",
                                "hp": 1
                            }
                        ]
                }
            },
            improvedigital: {
                usePrebidSizes: true
            },
            outbrain: {
                bidderUrl: 'https://b1h.zemanta.com/api/bidder/prebid/bid/',
                usersyncUrl: 'https://b1h.zemanta.com/usersync/prebid'
            },
            deviceAccess: true,
            userSync: {
                filterSettings: {
                    iframe: {
                        bidders: '*',
                        filter: 'include'
                    },
                    image: {
                        bidders: '*',
                        filter: 'include'
                    }
                },
                aliasSyncEnabled: true,
                auctionDelay: 30,
                syncsPerBidder: 8,
                syncDelay: 3000,
                userIds: [{
                    name: "criteo"
                },
                {
                    name: "pubProvided"
                },
                {
                    name: 'uid2'
                },
                {
                    name: 'pubCommonId',
                    bidders: [
                        'between'
                    ],
                    params: {
                        syncTime: 60 // in seconds, default is 24 hours
                    },
                    storage: {
                        name: 'pubCommonId',
                        type: 'cookie',
                        expires: 28
                    },
                },
                {
                    "name": "quantcastId"
                },
                {
                    name: "sharedId",
                    storage: {
                        type: "cookie",
                        name: "_sharedid",         // create a cookie with this name
                        expires: 365             // expires in 1 years
                    }
                },
                {
                    name: "id5Id",
                    params: {
                        partner: 12
                    },
                    storage: {
                        type: "html5",
                        name: "id5id",
                        expires: 45
                    }
                },
                {
                    name: "amxId",
                    storage: {
                        type: "html5",
                        name: "amxId",
                        expires: 1
                    }
                }
                ]
            },
            priceGranularity: "dense",
            glvMapping: {
                goodad: 32
            },
            floors: {},
            currency: {
                adServerCurrency: "USD"
            },
            enableSendAllBids: false,
            timeoutBuffer: 400,
            disableAjaxTimeout: true,
            maxRequestsPerOrigin: 1,
            enableTIDs: true,
            allowActivities: {
                fetchBids: {
                    rules: [{ allow: true }]
                },
                accessDevice: {
                    rules: [{ allow: true }]
                },
                syncUser: {
                    rules: [{ allow: true }]
                },
                transmitEids: {
                    rules: [{ allow: true }]
                },
                enrichEids: {
                    rules: [{ allow: true }]
                }
            },
            ortb2: {
                site: {
                    ext: {
                        data: {
                            pagetype: "",
                            category: ""
                        }
                    }
                }
            },
            useBidCache: true
        };

        if (rgpdActive()) {
            config.consentManagement = {
                gdpr: {
                    cmpApi: 'iab',
                    allowAuctionWithoutConsent: true, // suppress auctions if there's no GDPR consent string
                    defaultGdprScope: false,
                    timeout: 3000,  // GDPR timeout 3000ms
                    allowAuctionWithoutConsent: true,
                    rules: [{
                        vendorExceptions: ["between", "adtelligent", "smartyads", "goodad", "eplanning", "rise", "blueroostermedia", "adpone"],
                        enforcePurpose: false,
                        enforceVendor: false
                    }
                    ]
                }
            }
        };

        if (uspActive()) {
            config.consentManagement.usp = {
                cmpApi: 'iab',
                timeout: 50 // US Privacy timeout 100ms
            }
        };


        /*config.debugging = {
            enabled: true,
            intercept: [
                {
                    when: {
                        // intercept bids from bidderA that have adUnitCode === 'test-div'
                        adUnitCode: '2f513739-e884-4690-9d9e-e086185616c3video1',
                        bidder: 'smartyads'
                    },
                    then:
                    {
                        cpm: 10,
                        mediaType: "video",
                        source: "client",
                        currency: "EUR",
                        creativeId: Math.floor(Math.random() * 100000).toString(),
                        width: 640,
                        height: 480,
                        vastUrl: "https://basil79.github.io/vast-sample-tags/pg/vast.xml"
                    }

                }, {
                    when: {
                        // intercept bids from bidderA that have adUnitCode === 'test-div'
                        adUnitCode: '2f513739-e884-4690-9d9e-e086185616c3video2',
                        bidder: 'smartyads'
                    },
                    then:
                    {
                        cpm: 10,
                        mediaType: "video",
                        source: "client",
                        currency: "EUR",
                        creativeId: Math.floor(Math.random() * 100000).toString(),
                        width: 640,
                        height: 480,
                        vastUrl: "https://basil79.github.io/vast-sample-tags/pg/vast.xml"
                    }

                },
                {
                    when: {
                        // intercept bids from bidderA that have adUnitCode === 'test-div'
                        adUnitCode: '2f513739-e884-4690-9d9e-e086185616c3video3',
                        bidder: 'smartyads'
                    },
                    then:
                    {
                        cpm: 10,
                        mediaType: "video",
                        source: "client",
                        currency: "EUR",
                        creativeId: Math.floor(Math.random() * 100000).toString(),
                        width: 640,
                        height: 480,
                        vastUrl: "https://basil79.github.io/vast-sample-tags/pg/vast.xml"
                    }

                }
            ]
        };*/


        for (let param of params) {
            if (param.formatType == "VIDEO") {
                config.cache = {
                    url: "https://prebid.adnxs.com/pbc/v1/cache"
                }
            }

            let exitLoop = true;
            for (let bid of param.theMoneytizerAdUnit.bids) {
                if (bid.bidder == "adagio") {
                    config.realTimeData = {
                        dataProviders: [
                            {
                                name: "adagio",
                                params: {
                                    organizationId: bid.params.organizationId,
                                    site: bid.params.site
                                },
                            },
                        ],
                    };
                    exitLoop = true;
                    break;
                }
            }
            if (exitLoop == true) {
                break;
            }
        };

        d2ppbjs.que.push(function () {
            d2ppbjs.setConfig(config);
            d2ppbjs.enableAnalytics({
                provider: 'smartyads'
            });
        });
    }

    function getTags() {
        //Get dev2pub.js tags
        let allJsScript = document.getElementsByTagName('script');

        let tags = [];

        for (var i = 0; i < allJsScript.length; ++i) {
            let src = allJsScript[i].getAttribute("src");
            if (src) {
                if (src.includes("dev2pub.js")) {
                    allJsScript[i].launched = false;
                    tags.push(allJsScript[i]);
                }
            }
        }

        return tags;
    }

    /**
     * Load Dev2Pub Prebid library that build in our own Prebid project
     * TODO See if we load Prebid in parent page when the script is call in a iframe
     */
    function loadPrebid() {
        let pbjsScript = document.createElement("script");
        pbjsScript.type = "text/javascript";
        pbjsScript.defer = true;
        pbjsScript.id = "d2pPrebid";
        pbjsScript.src = DEV2PUB_JS_SCRIPT_URL + "/prebid.js";

        let pbjsTargetEl = document.getElementsByTagName("head")[0];
        pbjsTargetEl.insertBefore(pbjsScript, pbjsTargetEl.firstChild);

        return pbjsScript;
    }

    async function loadVideoJS(ads = [], idParam = "") {
        
            windowTop.animationLoad[idParam] = true;
            let playerJSScript = document.createElement("script");
            playerJSScript.async = true;
            playerJSScript.type = "text/javascript";
            playerJSScript.src = DEV2PUB_JS_SCRIPT_URL + "/player.js";

            playerJSScript.addEventListener("load", function () {
                let videoJSScript = document.createElement("script");
                videoJSScript.async = true;
                videoJSScript.type = "text/javascript";
                videoJSScript.src = DEV2PUB_JS_SCRIPT_URL + "/video.min.js";

                videoJSScript.addEventListener("load", function () {
                    let videoJSAdsScript = document.createElement("script");
                    videoJSAdsScript.async = true;
                    videoJSAdsScript.type = "text/javascript";
                    videoJSAdsScript.src = DEV2PUB_JS_SCRIPT_URL + "/videojs.ads.min.js";
                    videoJSAdsScript.addEventListener("load", function () {
                        let videoJSXScript = document.createElement("script");
                        videoJSXScript.async = true;
                        videoJSXScript.type = "text/javascript";
                        videoJSXScript.src = DEV2PUB_JS_SCRIPT_URL + "/videojsx.vast.js";
                        videoJSXScript.addEventListener("load", function () {
                            let schedules = [];
                            let offset = 0;
                            let promises = [];
                            if (ads != null && ads.length > 0) {
                                ads.forEach(ad => {
                                    if (ad[0].vastXml) {
                                        schedules.push({
                                            xml: ad[0].vastXml,
                                            offset: offset
                                        });
                                        offset += 15;
                                    }
                                    else {
                                        if (ad[0].vastUrl) {
                                            let promise = (async () => {
                                                let adToDisplay = await fetch(ad[0].vastUrl).then(res => res.text());
                                                if (typeof adToDisplay === 'string') {
                                                    schedules.push({
                                                        xml: adToDisplay,
                                                        offset: offset
                                                    });
                                                    offset += 15;
                                                } else {
                                                    console.log('Invalid VAST');
                                                }
    
                                            })();
    
                                            promises.push(promise);
    
                                        } else {
                                            if (ad[0].ad) {
                                                schedules.push({
                                                    xml: ad[0].ad,
                                                    offset: offset
                                                });
                                                offset += 15;
                                            }
                                            else {
                                                console.log('Invalid ad');
                                            }
                                        }
                                    }
    
    
                                });
                            }
                            

                            var companion = {
                                elementId: "companion",
                                maxWidth: 300,
                                maxHeight: 250
                            };

                            if (promises.length > 0) {
                                Promise.all(promises).then(() => {
                                    let videoDiv = '<video '
                                    + 'id=' + "video" + idParam + ' '
                                    + 'class="video-js vjs-default-skin" '
                                    + 'height="'+ads[0][0].height+'" '
                                    + 'width="'+ads[0][0].width+'" '
                                    + 'controls '
                                    + 'preload="auto" '
                                    + 'data-setup=\'{"autoplay":false}\'> '
                                    + '<source '
                                    + 'src="https://www.nordicmag.info/wp-content/uploads/2024/11/Dev2Pub-1080WebShareName.mov" '
                                    + 'type="video/mp4"'
                                    + '>'
                                    + '</video> '
                                    + '<div id="companion"></div>';

                                    let adDiv = document.getElementById(idParam);
                                    //adDiv.id = divId;
                                    adDiv.innerHTML = videoDiv;


                                    corner_instream_left(idParam, ads[0][0].width, ads[0][0].height, false, false);

                                    var player = videojs("video" + idParam);

                                    player.ready(function () {
                                        player.muted(true);

                                        var promise = player.play();

                                        if (promise !== undefined) {
                                            promise.then(function () {
                                                console.log("Autoplay started!");
                                            }).catch(function (error) {
                                                console.log("Autoplay was prevented.");
                                            });
                                        }
                                    });


                                    player.vast({ schedule: schedules, skip: 8, companion: companion });
                                });
                            }
                            else {
                                console.log("qsdsqdsqd");
                                let videoDiv = '<video '
                                    + 'id=' + "video" + idParam + ' '
                                    + 'class="video-js vjs-default-skin" '
                                    + 'height="250" '
                                    + 'width="300" '
                                    + 'controls '
                                    + 'preload="auto" '
                                    + 'data-setup=\'{"autoplay":false}\'> '
                                    + '<source '
                                    + 'src="https://www.nordicmag.info/wp-content/uploads/2024/11/Dev2Pub-1080WebShareName.mov" '
                                    + 'type="video/mp4"'
                                    + '>'
                                    + '</video> '
                                    + '<div id="companion"></div>';

                                    let adDiv = document.getElementById(idParam);
                                    //adDiv.id = divId;
                                    adDiv.innerHTML = videoDiv;


                                    corner_instream_left(idParam, 300, 250, false, false);

                                    var player = videojs("video" + idParam);

                                    player.ready(function () {
                                        player.muted(true);

                                        var promise = player.play();

                                        if (promise !== undefined) {
                                            promise.then(function () {
                                                console.log("Autoplay started!");
                                            }).catch(function (error) {
                                                console.log("Autoplay was prevented.");
                                            });
                                        }
                                    });

                            }
                        });



                        document.head.appendChild(videoJSXScript);
                    });

                    document.head.appendChild(videoJSAdsScript);
                })
                document.head.appendChild(videoJSScript);
            });

            document.head.appendChild(playerJSScript);

            let videoJSCSSScript = document.createElement("link");
            videoJSCSSScript.href = DEV2PUB_JS_SCRIPT_URL + "/video-js.min.css";
            videoJSCSSScript.type = "text/css";
            videoJSCSSScript.rel = "stylesheet";
            videoJSCSSScript.media = "all";

            let pbjsTargetEl = document.getElementsByTagName("head")[0];
            pbjsTargetEl.insertBefore(videoJSCSSScript, pbjsTargetEl.lastChild);


            let videoJSAdsCSSScript = document.createElement("link");
            videoJSAdsCSSScript.href = DEV2PUB_JS_SCRIPT_URL + "/videojs.ads.css";
            videoJSAdsCSSScript.type = "text/css";
            videoJSAdsCSSScript.rel = "stylesheet";
            videoJSAdsCSSScript.media = "all";

            pbjsTargetEl = document.getElementsByTagName("head")[0];
            pbjsTargetEl.insertBefore(videoJSAdsCSSScript, pbjsTargetEl.lastChild);


            let videoJSXCSSScript = document.createElement("link");
            videoJSXCSSScript.href = DEV2PUB_JS_SCRIPT_URL + "/videojsx.vast.css";
            videoJSXCSSScript.type = "text/css";
            videoJSXCSSScript.rel = "stylesheet";
            videoJSXCSSScript.media = "all";

            pbjsTargetEl = document.getElementsByTagName("head")[0];
            pbjsTargetEl.insertBefore(videoJSXCSSScript, pbjsTargetEl.lastChild);
    }


    function getTagId(tag) {
        let dev2pub_queryString = tag.src.replace(/^[^\?]+\??/, '');
        let dev2pub_params = parseQuery(dev2pub_queryString);

        if (dev2pub_params.id) {
            let id = dev2pub_params.id.replace("'", "").replace("'", "").replace('"', "").replace('"', "");
            return id;
        }
        else {
            return "";
        }
    }

    function loadTheMoneytizerTag(param) {
        const scriptEl = document.createRange().createContextualFragment(param.theMoneytizerTag);

        if (param.idTheMoneytizerFormatName == "16") {
            //RECO CONTENU
            let recoDiv = document.createElement("div");
            recoDiv.style.width = "50%";
            recoDiv.style.marginRight = "auto";
            recoDiv.style.marginLeft = "auto";
            recoDiv.appendChild(scriptEl);
            document.getElementsByTagName('body')[0].appendChild(recoDiv);
        }
        else {
            document.getElementsByTagName('head')[0].appendChild(scriptEl);
        }

        launchNextTags();
    }



    function requestBids(params, adUnits, firstLaunch, dev2pubRefreshInterval) {
        setConfig(params);
        if (d2ppbjs.adUnits.length > 0) {
            console.log("Request bids");

            d2ppbjs.que.push(function () {
                d2ppbjs.requestBids({
                    bidsBackHandler: function (bidResponses) {
                        if (firstLaunch) {
                            sendResponsesTime(params);
                        }
                        prebidBidsBackHandler(params, bidResponses, firstLaunch);
                    },
                    timeout: 3000
                });
            });
        }
    }

    function sendResponsesTime(params) {
        let responsesTime = [];
        let adUnits = d2ppbjs.getNoBids();

        for (let adunitCode in adUnits) {
            adUnits[adunitCode]["bids"].forEach(bid => {
                let responseTime = {
                    bidderCode: bid.bidder,
                    delay: bid["metrics"].getMetrics()["adapter.client.total"],
                    idSite: params[0].idSite
                }

                let responseTimeExist = false;
                for (let responseTimeKey in responsesTime) {
                    if (responsesTime[responseTimeKey].bidderCode == bid.bidder) {
                        responseTimeExist = true;
                    }
                }
                if (responseTimeExist == false) {
                    responsesTime.push(responseTime);
                }
            });
        }

        /*if (responsesTime.length > 0) {
            let dev2pub_sendResponsesTimeXmlhttp = new XMLHttpRequest();

            dev2pub_sendResponsesTimeXmlhttp.onreadystatechange = function () {
                if (dev2pub_sendResponsesTimeXmlhttp.readyState == XMLHttpRequest.DONE) {
                    console.log("Response time save");
                }
                else if (dev2pub_sendResponsesTimeXmlhttp.status == 400) {
                    console.log('There was an error 400');
                    console.log(dev2pub_sendResponsesTimeXmlhttp.responseText);
                }
                else {
                    if (dev2pub_sendResponsesTimeXmlhttp.status != 200 && dev2pub_sendResponsesTimeXmlhttp.status != 201 && dev2pub_sendResponsesTimeXmlhttp.status != 0) {
                        console.log('something else other than 200 was returned');
                        console.log(dev2pub_sendResponsesTimeXmlhttp.responseText);
                        console.log(dev2pub_sendResponsesTimeXmlhttp.status);
                    }
                }
            }

            dev2pub_sendResponsesTimeXmlhttp.open("POST", DEV2PUB_API_URL + "/api/public/Dev2Pub/SendResponseTime/", true);
            dev2pub_sendResponsesTimeXmlhttp.setRequestHeader('Content-type', 'application/json');
            dev2pub_sendResponsesTimeXmlhttp.send(JSON.stringify(responsesTime));
        }*/
    }

    function prebidBidsBackHandler(params, bidResponses, firstLaunch) {
        let vastURL = "";
        if (bidResponses) {
            for (let param of params) {
                if (param.formatType == "VIDEO" && param.videoContext == "instream") {
                    let ads = [];
                    let ad1 = d2ppbjs.getHighestCpmBids(param.theMoneytizerAdUnit.code + "video1");
                    let ad2 = d2ppbjs.getHighestCpmBids(param.theMoneytizerAdUnit.code + "video2");
                    let ad3 = d2ppbjs.getHighestCpmBids(param.theMoneytizerAdUnit.code + "video3");

                    if (ad1 && ad1.length > 0) {
                        ads.push(ad1);
                    }

                    if (ad2 && ad2.length > 0) {
                        ads.push(ad2);
                    }

                    if (ad3 && ad3.length > 0) {
                        ads.push(ad3);
                    }

                    if (ads != null && ads.length > 0) {
                        loadVideoJS(ads, param.divId);

                        console.log("Bid success");
                        ads.forEach(ad => {
                            d2ppbjs.markWinningBidAsUsed(ad[0]);
                            d2ppbjs.removeAdUnit(ad[0].adUnitCode);
                            sendSuccess(ad[0]);
                        })
                    }
                    else{
                        console.log("No bids");
                        if (firstLaunch) {
                            loadVideoJS("", param.divId);
                            sendFailed(param);
                            let tag = document.getElementById(param.id + "script");
                            if (tag) {
                                if (!param.dev2pubPassbackRun) {
                                    runPassback(param, tag, param.divId);
                                }
                            }
                        }
                    }
                }
                else {
                    let ad = d2ppbjs.getHighestCpmBids(param.theMoneytizerAdUnit.code);

                    if (ad && ad.length > 0) {
                        var sizeOk = false;
                        param.theMoneytizerAdUnit.sizes.forEach(size => {
                            if (ad[0].width == size[0] && ad[0].height == size[1]) {
                                sizeOk = true;
                            }
                        });

                        if (sizeOk == true || param.formatType == "VIDEO") {
                            if (param.formatType != "VIDEO") {
                                
                                let divId = param.id;

                                if (inIFrame()) {
                                    if (!window.frameElement.id) {
                                        window.frameElement.id = params.id;
                                    }
                                    divId = window.frameElement.id;
                                }

                                let idIFrame = divId + "frame";

                                let iFrame = "<iframe id='" + idIFrame + "'"
                                    + " FRAMEBORDER=\"0\""
                                    + " SCROLLING=\"no\""
                                    + " MARGINHEIGHT=\"0\""
                                    + " MARGINWIDTH=\"0\""
                                    + " TOPMARGIN=\"0\""
                                    + " LEFTMARGIN=\"0\""
                                    + " ALLOWTRANSPARENCY=\"true\""
                                    + " WIDTH=\"0\""
                                    + " HEIGHT=\"0\">."
                                    + " </iframe>";

                                let adDiv = document.getElementById(param.divId);
                                //adDiv.id = divId;
                                adDiv.innerHTML = iFrame;

                                var iframe = document.getElementById(idIFrame);

                                var iframeDoc = iframe.contentWindow.document;

                                iframeDoc.open('text/html', 'replace');
                                iframeDoc.close();

                                try { 
                                    d2ppbjs.renderAd(iframeDoc, ad[0]['adId']);
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                            else {
                                if (param.videoContext == "OUTSTREAM") {
                                    var videoDiv = document.getElementById(param.id);
                                    d2ppbjs.renderAd(videoDiv, ad[0]['adId']);
                                }
                            }

                            console.log("Bid success");

                            let winningBids = d2ppbjs.getAllWinningBids();

                            if (winningBids) {
                                for (let winningBid of winningBids) {
                                    //Display animation
                                    if (winningBid.adId == ad[0].adId) {

                                        if (windowTop.animationLoad[param.id] == null || windowTop.animationLoad[param.id] == false) {
                                            launchFormat(param.divId, ad[0].height, ad[0].width, param.formatJsName, false, false, vastURL);
                                            windowTop.animationLoad[param.id] = true;

                                        }
                                        else {
                                            console.log("Animation already load");

                                            if (param.formatType != "VIDEO") {
                                                launchFormat(param.divId, ad[0].height, ad[0].width, param.formatJsName, false, true, vastURL);
                                            }
                                        }
                                    }
                                }

                                sendSuccess(ad[0]);
                            }
                        }
                        else {
                            console.log("Ads sizes are not in adunit");
                            if (firstLaunch) {
                                sendFailed(param);
                                let tag = document.getElementById(param.id + "script");
                                if (tag) {
                                    if (!param.dev2pubPassbackRun) {
                                        runPassback(param, tag, param.divId);
                                    }
                                }

                            }
                        }
                    }
                    else {
                        console.log("No bids");
                        if (firstLaunch) {
                            sendFailed(param);
                            let tag = document.getElementById(param.id + "script");
                            if (tag) {
                                if (!param.dev2pubPassbackRun) {
                                    runPassback(param, tag, param.divId);
                                }
                            }
                        }
                    }
                }

            }

            d2ppbjs.resetAuctions();
        }


        //launchNextTags();
    }

    function sendSuccess(ad) {
        if (ad) {
            //Send bid to dev2pub server
            let displayAds = [];

            let idSiteFormat = "";
            if (ad.params && ad.params[0] && ad.params[0].idSiteFormat) {
                idSiteFormat = ad.params[0].idSiteFormat;
            }
            else {
                idSiteFormat = ad.adUnitCode
            }

            displayAds.push(
                {
                    "IdSiteFormat": idSiteFormat,
                    "BidderCode": ad.bidderCode,
                    "Cpm": ad.cpm,
                    "Currency": ad.currency,
                    "Width": ad.width,
                    "Height": ad.height,
                    "MediaType": ad.mediaType,
                    "NetRevenue": ad.netRevenue,
                    "Size": ad.size,
                    "StatusMessage": ad.statusMessage,
                    //"Status": ad.status,
                    //"Params": JSON.stringify(ad.params),
                    //"AdId": ad.adId,
                    //"AuctionId": ad.auctionId,
                    "AdUnitCode": ad.adUnitCode,
                    "BidFloor": ad.bidFloor,
                    "IsRefresh": isRefresh
                }
            );

            if (displayAds.length > 0) {
                let dev2pub_sendBidXmlhttp = new XMLHttpRequest();

                dev2pub_sendBidXmlhttp.onreadystatechange = function () {
                    if (dev2pub_sendBidXmlhttp.readyState == XMLHttpRequest.DONE) {
                        console.log("Bid save");
                    }
                    else if (dev2pub_sendBidXmlhttp.status == 400) {
                        console.log('There was an error 400');
                        console.log(dev2pub_sendBidXmlhttp.responseText);
                    }
                    else {
                        if (dev2pub_sendBidXmlhttp.status != 200 && dev2pub_sendBidXmlhttp.status != 201 && dev2pub_sendBidXmlhttp.status != 0) {
                            console.log('something else other than 200 was returned');
                            console.log(dev2pub_sendBidXmlhttp.responseText);
                            console.log(dev2pub_sendBidXmlhttp.status);
                        }
                    }
                }

                dev2pub_sendBidXmlhttp.open("POST", DEV2PUB_API_URL + "/api/public/Dev2Pub/SendAdDisplay/", true);
                dev2pub_sendBidXmlhttp.setRequestHeader('Content-type', 'application/json');
                dev2pub_sendBidXmlhttp.send(JSON.stringify(displayAds));

                isRefresh = true;
            }
        }
    }

    function sendFailed(params) {
        let noBids = [];

        noBids.push({
            "IdSiteFormat": params.id,
            "BidFloor": params.bidFloor,
            "Failed": true
        });

        let sendFailedRequest = new XMLHttpRequest();
        sendFailedRequest.onreadystatechange = function () {
            if (sendFailedRequest.readyState == XMLHttpRequest.DONE) {

                //console.log(dev2pub_sendBidXmlhttp.responseText);
            }
            else if (sendFailedRequest.status == 400) {
                console.log('There was an error 400');
                console.log(sendFailedRequest.responseText);
            }
            else {
                if (sendFailedRequest.status != 200 && sendFailedRequest.status != 201 && sendFailedRequest.status != 0) {
                    console.log('something else other than 200 was returned');
                    console.log(sendFailedRequest.responseText);
                    console.log(sendFailedRequest.status);
                }
            }
        }

        //Send the proper header information along with the request
        sendFailedRequest.open("POST", DEV2PUB_API_URL + "/api/public/Dev2Pub/SendAdDisplay/", true);
        sendFailedRequest.setRequestHeader('Content-type', 'application/json');
        sendFailedRequest.send(JSON.stringify(noBids));

        console.log("Bid failed save");
    }

    function runPassback(param, tag, divId) {
        if (tag) {
            if (param.passback) {
                console.log("Run passback");
                if (inIFrame()) {
                    const scriptEl = parent.document.createRange().createContextualFragment(params.passback);
                    let divToAppend = parent.document.getElementById(divId);
                    divToAppend.parentNode.insertBefore(scriptEl, divToAppend.nextSibling);


                    /*const scriptEl = document.createRange().createContextualFragment(param.passback);
                    tag.parentNode.insertBefore(scriptEl, tag.nextSibling);*/
                }
                else {
                    const scriptEl = document.createRange().createContextualFragment(param.passback);
                    tag.parentNode.insertBefore(scriptEl, tag.nextSibling);
                }
                
                d2ppbjs.removeAdUnit(param.theMoneytizerAdUnit.code);
                param.dev2pubPassbackRun = true;
                //dev2pubPassbackRun = true;

            }
            else {
                //if not passback, we re launch every minutes
                /*console.log("Passback");
                setInterval(function(){requestBids(params, tag, divId, false);},60000);*/
            }
        }
    }

})(window);
