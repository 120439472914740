import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js";

export function banner_atf_bottom_over_fixed(id, width, height, refresh, demo) {
    if (id && width && height) {
        let div;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
        }
        else {
            div = document.getElementById(id);
        }
       
        //document.body.innerHTML += '<div id="' + id + '"></div>';
        if (div) {
            //div.style.backgroundColor = "red";
            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.left = 0;
            div.style.right = 0;
            div.style.margin = "0px auto";
            div.style.position = "absolute";
            div.style.bottom = 0;
            div.style.zIndex = 16777271;

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            displayFormatLinks(div);

            id = null;
        }
    }
}